import React, { useState, useEffect } from 'react';
import { 
  SafeAreaView, 
  StyleSheet, 
  View, 
  Text, 
  FlatList, 
  Image, 
  TouchableOpacity, 
  ActivityIndicator, 
  TextInput, 
  ScrollView 
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [userData, setUserData] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/GetBrandListings.php');
      const result = await response.json();
      setData(result);
      setFilteredData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/GetSessionData.php');
      const result = await response.json();
      setUserData(result);
    } catch (error) {
      console.error('Error fetching user data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUserData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = data.filter(item =>
        item.BrandName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const handleCardPress = (listingID) => {
    navigation.navigate('CreateSubmissionPage', { ListingID: listingID });
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/EndSession.php');
      await response.json();
      setUserData({});
      // Reload the app after logout
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEditProfile = () => {
    window.open('https://collabbay.com/UpdateProfile.html');
  };

  const handlePersonIconPress = () => {
    if (userData.Username) {
      navigation.navigate('UserRecapPage');
    } else {
      navigation.navigate('LogInPage');
    }
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.card}
      onPress={() => handleCardPress(item.ListingID)}
    >
      <Image source={{ uri: item.LogoImage }} style={styles.logoImage} />
      <View style={styles.cardContent}>
        <Text style={styles.brandName}>{item.BrandName}</Text>
        <Text style={styles.description}>
          {item.ListingDescription.length > 40
            ? item.ListingDescription.substring(0, 40) + '...'
            : item.ListingDescription}
        </Text>
        <View style={styles.creditsContainer}>
          <Text style={styles.credits}>£{item.Credits}</Text>
        </View>
        <View style={styles.locationContainer}>
          <Ionicons name="location-outline" size={16} color="#000" />
          <Text style={styles.locationText}>{item.FullAddress}</Text>
          <Text style={styles.locationText}>{item.PreferredAvailability}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={28} color="black" />
        </TouchableOpacity>
        <TextInput
          style={styles.searchBar}
          placeholder="Search..."
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
        {loading ? (
          <ActivityIndicator size="large" color="#000" />
        ) : (
          <FlatList
            data={filteredData}
            renderItem={renderItem}
            keyExtractor={(item) => item.ListingID.toString()}
            contentContainerStyle={styles.list}
          />
        )}

<Text style={styles.customiseCTA}>Prepare your profile to be able to apply to collabs, coming Autumn 2024.</Text>

<Text style={styles.customiseCTAsub}>Stand by... we have some good things in store. From dining and experiences, to fitness and wellbeing, more brands are joining the platform over the coming months and we can't wait for you to connect.</Text>


      </ScrollView>
      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
          <Ionicons name="qr-code" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('QRCodeScannerPage')}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={handlePersonIconPress}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 70, // Added padding to account for the back button height
  },
  searchBar: {
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#ccc',
    paddingHorizontal: 15,
    marginVertical: 20,
    backgroundColor: '#f9f9f9',
    fontSize: 16,
  },
  list: {
    paddingBottom: 80, // Added padding for footer visibility
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 15,
    marginVertical: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  logoImage: {
    width: '100%',
    height: 120,
    borderRadius: 10,
    marginBottom: 10,
    resizeMode: 'cover',
  },
  cardContent: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  brandName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 5,
  },
  customiseCTA: {
    fontSize: 24,
    marginTop: '40',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 5,
    textalign: 'center',
  },
  customiseCTAsub: {
    fontSize: 15,
    marginTop: '10',
    color: '#b2b2b2',
    marginBottom: 5,
    textalign: 'center',
  },
  description: {
    fontSize: 16,
    color: '#000',
    marginBottom: 10,
  },
  creditsContainer: {
    backgroundColor: '#EEEDEB',
    borderRadius: 20,
    padding: 5,
    marginBottom: 10,
  },
  credits: {
    fontSize: 16,
    color: '#000',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationText: {
    fontSize: 14,
    color: '#000',
    marginLeft: 5,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: '#fff',
  },
  footerIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
});

export default App;
