import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
  Modal,
  Pressable,
  Linking,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {
  const [searchText, setSearchText] = useState('');
  const [userData, setUserData] = useState({});
  const [recentCollabs, setRecentCollabs] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    fetchUserData();
    fetchRecentCollabs();
  }, []);

  useEffect(() => {
    console.log("Current step: ", currentStep);
    console.log("Is tutorial showing: ", showTutorial);
  }, [currentStep, showTutorial]);

  const tutorialSteps = [
    {
      message: `Welcome to Collabbay, the platform that connects influencers like you with top brands and unique experiences—from dining to spa days and more. Get access to exclusive offers in exchange for creating engaging content during your visits.`,
      icon: null,
    },
    {
      message: `Browse brand collab offers, find something you like? Simply book a visit and await approval, we'll need to verify you before you can create bookings.`,
      icon: 'search',
    },
    {
      message: `Manage and view upcoming collabs via your calendar, your central manager for tracking upcoming brand collabs at different venues.`,
      icon: 'calendar',
    },
    {
      message: `Each collab has a QR-code card linked to the offer. Instead of paying at venues, present your QR for the brand to scan and redeem your offer. View and manage your QR vouchers via this dashboard.`,
      icon: 'qr-code',
    },
    {
      message: `Your profile is seen by brands, as well as us. Add a profile picture and a bio with your interests.`,
      icon: 'person',
    },
    {
      message: `Welcome to Collabbay, where collabs are your new currency. Update your profile and await a verification message. New venues and offers will be listed on collabbay recurringly.`,
      icon: null,
    },
  ];

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/GetSessionData.php');
      const data = await response.json();
      setUserData(data);

      // If the user hasn't seen the tutorial, show it
      if (data.HasSeenTutorial === 0) {
        setShowTutorial(true);
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
    }
  };

  const fetchRecentCollabs = () => {
    fetch('https://collabbay.com/APIControllers/RetrieveRecentCollabs.php')
      .then(response => response.json())
      .then(data => {
        setRecentCollabs(data);
      })
      .catch(error => {
        console.error('Error fetching recent collabs: ', error);
      });
  };

  const handleSearch = () => {
    if (searchText.trim() !== '') {
      navigation.navigate('CreateSubmissionPage', { ListingID: searchText });
    }
  };

  const handlePersonIconPress = () => {
    if (userData.Username) {
      navigation.navigate('UserRecapPage');
    } else {
      navigation.navigate('LogInPage');
    }
  };

  const handleOfferPress = () => {
    navigation.navigate('BrandListings');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/EndSession.php');
      const data = await response.json();
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
        navigation.navigate('Homepage');
      } else {
        Alert.alert('Logout', 'No active session found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleScanIconPress = () => {
    Linking.openURL('https://collabbay.com/QR.html');
  };

  const handleNextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateHasSeenTutorial();  // Update on the server
      setShowTutorial(false);  // Hide the tutorial
    }
  };

  const updateHasSeenTutorial = () => {
    fetch('https://collabbay.com/APIControllers/UpdateHasSeenTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID: userData.UserID,
        HasSeenTutorial: 1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status !== 'Success') {
          console.error('Error updating tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error updating tutorial status: ', error);
      });
  };

  const handleRedoTutorial = () => {
    fetch('https://collabbay.com/APIControllers/RedoTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status === 'Success') {
          console.log('Tutorial reset successfully.');
          setCurrentStep(0);  // Reset to the first step
          setShowTutorial(true);  // Make sure tutorial shows again
        } else {
          console.error('Error resetting tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error resetting tutorial status: ', error);
      });
  };

  const renderTutorialButton = () => (
    <TouchableOpacity style={styles.redoButton} onPress={handleRedoTutorial}>
       <Ionicons name="help-circle" size={20} color="black" />
    </TouchableOpacity>
  );

  const renderTutorial = () => {
    if (!showTutorial || currentStep >= tutorialSteps.length) {
      return null;
    }

    const step = tutorialSteps[currentStep];

    return (
      <Modal transparent visible={showTutorial} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.tutorialCard}>
            {step.icon && (
              <Ionicons name={step.icon} size={60} color="black" style={styles.iconLarge} />
            )}
            <Text style={styles.tutorialText}>{step.message}</Text>
            <Pressable style={styles.nextButton} onPress={handleNextStep}>
              <Text style={styles.buttonText}>
                {currentStep < tutorialSteps.length - 1 ? 'Next' : 'Finish'}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      {renderTutorialButton()}
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.bannerContainer}>
          <Image
            style={styles.bannerImage}
            source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
            resizeMode="contain"
          />
        </View>

        <View style={styles.section}>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Time to collab.</Text>
            {!userData.Username && (
              <TouchableOpacity
                style={styles.signUpButton}
                onPress={() => navigation.navigate('LogInPage')}
              >
                <Text style={styles.signUpButtonText}>Sign up as a content creator</Text>
              </TouchableOpacity>
            )}
          </View>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Create.</Text>
              <TouchableOpacity onPress={() => navigation.navigate('CreateSubmissionPage')}>
                <Text style={styles.cardSubTitle}>Create content.</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Post.</Text>
              <Text style={styles.cardSubTitle}>Share content.</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Exchange.</Text>
              <Text style={styles.cardSubTitle}>Redeem views.</Text>
            </View>
          </ScrollView>
        </View>

        <View style={styles.offerContainer}>
          <TouchableOpacity style={styles.offerButton} onPress={handleOfferPress}>
            <Ionicons name="search" size={24} color="black" style={styles.offerIcon} />
            <Text style={styles.offerText}> Explore Brand Offers</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
            <Text style={styles.offerLogText}>
              {!userData.Username && 'Log in to view'}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.scanOptionalContainer}>
          <Text>or scan the QR in-venue</Text>
        </View>

        <View style={styles.bottomBackground}>
          <TouchableOpacity onPress={handleScanIconPress}>
            <Ionicons name="scan" size={200} color="black" style={styles.qrIcon} />
          </TouchableOpacity>

          <View style={styles.searchBar}>
            <Ionicons name="search" size={24} color="white" style={styles.searchIcon} />
            <TextInput
              style={styles.searchInput}
              placeholder="Find a collabbay by ID"
              placeholderTextColor="white"
              onChangeText={(text) => setSearchText(text)}
              onSubmitEditing={handleSearch}
            />
          </View>

          {userData.Username && recentCollabs.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>My Recent collabs</Text>
              {recentCollabs.map((collab, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.collabCard}
                  onPress={() => navigation.navigate('CollabCampaignDetailsPage', { CollabID: collab.CollabID })}
                >
                  <Text style={styles.collabCardTitle}>{collab.BrandName}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.DateTime}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.AdHeader}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      </ScrollView>

      <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
        <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy. </Text>
      </TouchableOpacity>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
          <Ionicons name="qr-code" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={handleScanIconPress}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={handlePersonIconPress}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>

      {renderTutorial()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  searchBar: {
    backgroundColor: 'black',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 40,
    marginBottom: 10,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  card: {
    width: 150,
    height: 200,
    backgroundColor: 'black',
    marginRight: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
  },
  cardSubTitle: {
    color: 'white',
    marginTop: 5,
    textAlign: 'center',
  },
  collabCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  collabCardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  collabCardSubTitle: {
    fontSize: 14,
    color: 'gray',
  },
  qrIcon: {
    alignSelf: 'center',
    marginTop: 20,
  },
  redoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#FDF05D',
    padding: 6,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 10,
    paddingBottom: 60,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  signUpButton: {
    backgroundColor: '#FDF05D',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  signUpButtonText: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
  },
  offerButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    marginTop: 10,
    marginBottom: 10,
    width: '75%',
    justifyContent: 'center',
  },
  offerIcon: {
    marginRight: 10,
  },
  offerText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  offerLogText: {
    color: 'black',
    fontSize: 12,
    fontWeight: 'bold',
  },
  offerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  scanOptionalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tutorialCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: '80%',
  },
  iconLarge: {
    marginBottom: 15,
  },
  tutorialText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  nextButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#000',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});

export default App;
